






































import { mixins } from "vue-class-component"
import { Component, Vue } from "vue-property-decorator"
import { getInventories } from "@/select/api/inventory"
import { IInventoryData } from "@/api/types"
import Pagination from "@/components/Pagination/index.vue"
import { PageMixin, ListMixin } from "@/mixins"

const defaultListQuery = {
	page: 1,
	page_size: 20,
	ordering: "name",
	name: undefined,
}

@Component({
	name: "Inventories",
	components: { Pagination },
})
export default class extends mixins(PageMixin, ListMixin) {
	getList = getInventories

	async beforeRouteEnter(to, from, next) {
		const listQuery = { ...defaultListQuery, name: to.query.q, is_bundle: 1 }
		const { data } = await getInventories(listQuery)
		next((vm) => vm.setData(data))
	}

	mounted() {
		this.listQuery.ordering = "name"
	}

	private async search() {
		this.$router.push({ query: { q: this.listQuery.name } })
		this.listQuery.page = 1
		this.load()
	}
}
