










































import { ref as _ref } from "@vue/composition-api";
import Pagination from "@/components/Pagination/index.vue";
import router from "@/router";
import Inventories, { InventoryData, Params } from "@/select2/inventory";
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  let count = _ref(0);

  let listQuery = _ref(new Params(router.currentRoute.query));

  let inventories = _ref(([] as InventoryData[]));

  let loading = _ref(true);

  async function load() {
    loading.value = true;
    const data = await Inventories.list(listQuery.value.toRouterQuery());
    inventories.value = data.results;
    count.value = data.count;
    loading.value = false;
  }

  async function search() {
    listQuery.value.page = 1;
    load();
  }

  onMounted(() => {
    load();
  });
  return {
    count,
    listQuery,
    inventories,
    load,
    search
  };
};

__sfc_main.components = Object.assign({
  Pagination
}, __sfc_main.components);
export default __sfc_main;
